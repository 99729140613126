/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/jquery/jquery@2.2.4/dist/jquery.min.js
 * - /gh/mailcheck/mailcheck@1.1.0/src/mailcheck.min.js
 * - /npm/vanilla-lazyload@10.20.1/dist/lazyload.min.js
 * - /npm/jquery-ui-dist@1.12.1/jquery-ui.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
